import { AppRoutes } from 'config/routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import {
  useCreateAgroProductionDocumentMutation,
  useCreateFuelDocumentMutation,
  useCreateLicenseDocumentMutation,
  useCreateSupplyProductionDocumentMutation,
  useGetAgroProductionDocumentSchemeQuery,
  useGetFuelDocumentSchemeQuery,
  useGetLicenseDocumentSchemeQuery,
  useGetPartnerDocumentSchemeQuery,
  useGetSupplyDocumentSchemeQuery,
} from 'store/api/documents';
import { useHandleMutation } from 'utils/handleMutation';
import {
  generateModelFromScheme,
  generateValidationSchema,
  parseSteps,
} from 'utils/sheme-parser';
import { useTranslation } from 'react-i18next';

export const useNewContract = () => {
  const { id: documentId } = useParams();
  const navigate = useNavigate();

  const {
    i18n: { language },
  } = useTranslation();

  const templateId = new URLSearchParams(useLocation().search).get('templateId');

  const path = useLocation().pathname;
  const { Home, Contracts } = AppRoutes.Dashboard;

  const [step, setStep] = useState<number>(1);
  const { data: fuelScheme } = useGetFuelDocumentSchemeQuery(
    {
      documentId: documentId ?? '',
    },
    { skip: true },
  );
  const { data: licenseScheme } = useGetLicenseDocumentSchemeQuery(
    {
      documentId: documentId ?? '',
    },
    { skip: true },
  );
  const { data: partnerScheme } = useGetPartnerDocumentSchemeQuery(
    {
      documentId: documentId ?? '',
    },
    { skip: true },
  );
  const { data: agroProductsScheme } = useGetAgroProductionDocumentSchemeQuery(
    {
      documentId: documentId ?? '',
    },
    { skip: false },
  );
  const { data: supplyDocumentScheme } = useGetSupplyDocumentSchemeQuery(
    {
      documentId: documentId ?? '',
    },
    { skip: false },
  );

  const [createFuelDocument, createFuelDocumentMutation] = useCreateFuelDocumentMutation();
  const [createLicenseDocument, createLicenseDocumentMutation] =
    useCreateLicenseDocumentMutation();
  const [createAgroDocument, createAgroDocumentMutation] =
    useCreateAgroProductionDocumentMutation();
  const [createSupplyDocument, createSupplyDocumentMutation] =
    useCreateSupplyProductionDocumentMutation();

  const dropDownOptions = useMemo(
    () =>
      [
        partnerScheme && {
          value: 'Partner',
          label: 'Партнерский договор',
          scheme: partnerScheme,
          templateId: 'd0f0d31f-7ca5-4af6-a1d3-808ce2b08e1f',
        },
        licenseScheme && {
          value: 'License',
          label: 'Сублицензионный договор',
          scheme: licenseScheme,
          templateId: 'cc9d6276-8e60-4acf-a243-2c9b40e02155',
        },
        fuelScheme && {
          value: 'Fuel',
          label: 'Договор  поставки нефтепродуктов',
          scheme: fuelScheme,
          templateId: '7e27cd52-1c33-4d67-8adf-8d8b5230acaa',
        },
        agroProductsScheme && {
          value: 'AgroSupply',
          label: 'Договор поставки сельскохозяйственной продукции',
          scheme: agroProductsScheme,
          templateId: '7c50bf38-2cb2-437d-996f-7b835775c36e',
        },
        supplyDocumentScheme && {
          value: 'NpSupply',
          label: 'Соглашение к договору поставки',
          scheme: supplyDocumentScheme,
          templateId: '80bdeb20-bd4b-4433-a4a9-3bccb999ac90',
        },
      ]?.filter((item) => item),
    [partnerScheme, licenseScheme, fuelScheme, agroProductsScheme, supplyDocumentScheme],
  );

  const [currentSelect, setCurrentSelect] = useState(dropDownOptions[0]);
  const isApproval = path.includes(AppRoutes.Dashboard.Contracts.ContractApproval);

  const openDocumentSign = (documentId: string) => {
    navigate(
      Home +
        Contracts.Home +
        Contracts.NewContract +
        Contracts.PreviewNewContract +
        `/${documentId}`,
    );
  };

  const onSuccessCreate = useCallback(
    (id: string) => {
      openDocumentSign(id);
    },
    [navigate, openDocumentSign],
  );

  useHandleMutation({ ...createFuelDocumentMutation, onSuccess: onSuccessCreate });
  useHandleMutation({ ...createLicenseDocumentMutation, onSuccess: onSuccessCreate });
  useHandleMutation({ ...createAgroDocumentMutation, onSuccess: onSuccessCreate });
  useHandleMutation({ ...createSupplyDocumentMutation, onSuccess: onSuccessCreate });

  useEffect(() => {
    if (templateId) {
      const select = dropDownOptions.find((item) => item.templateId === templateId);
      if (select) {
        setCurrentSelect(select);
      }
    } else {
      setCurrentSelect(dropDownOptions[0]);
    }
  }, [dropDownOptions]);

  const { currentScheme, model, steps, stepsLabels } = useMemo(() => {
    return {
      currentScheme: currentSelect?.scheme,
      model: generateModelFromScheme(currentSelect?.scheme, language),
      ...parseSteps(currentSelect?.scheme),
    };
  }, [currentSelect]);

  const getValidationSchema = () => {
    if (isApproval) {
      return Yup.object().shape({});
    }
    return generateValidationSchema(currentScheme, step);
  };

  return {
    step,
    steps,
    model,
    setStep,
    isApproval,
    stepsLabels,
    currentSelect,
    currentScheme,
    dropDownOptions,
    setCurrentSelect,
    openDocumentSign,
    createAgroDocument,
    createFuelDocument,
    createSupplyDocument,
    getValidationSchema,
    createLicenseDocument,
  };
};
